export const queries = (
  filtering: string,
  orderingAndLimit: string,
  fields: string,
) => ({
  getFirstPage: () => groq`*[${filtering}]|${orderingAndLimit}${fields}`,
  getNextPage: () =>
    groq`*[${filtering} && ((dateTime(publishedAt) < dateTime($lastPublishedAt)) || (dateTime(publishedAt) == dateTime($lastPublishedAt) && _id > $lastId))]|${orderingAndLimit}${fields}`,
  getBySlug: () =>
    groq`*[_type == $type && slug.current == $slug][0]{name, slug, _id}`,
})
